/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.footerBottomRow {
  width: calc(100% - (2 * 36px));
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 27px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .footerBottomRow {
    width: 100%;
    margin: 0;
    padding: 0 9px 36px 9px;
  }
}

.legalLinkWrapper {
  display: flex;
  flex-flow: row wrap;
}

.recaptchaTermsWrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  margin-top: 18px;
  padding-left: 9px;
}
@media (max-width: 768px) {
  .recaptchaTermsWrapper {
    padding-right: 9px;
    margin-top: 9px;
    color: #666;
  }
}

.recaptchaLink {
  color: #222;
}
.recaptchaLink:hover {
  color: #444;
}
@media (max-width: 768px) {
  .recaptchaLink {
    text-align: center;
    color: #666;
  }
}

@media (max-width: 768px) {
  .copyrightLink {
    margin-top: 18px;
    text-align: center;
    color: #666;
  }
}

.monogramIconWrapper {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}
@media (max-width: 768px) {
  .monogramIconWrapper {
    margin-top: 18px;
  }
}

.monogramIcon {
  width: 100%;
  height: 72px;
  text-align: center;
}