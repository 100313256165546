.footerBottomRow {
  width: calc(100% - 72px);
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

@media (width <= 768px) {
  .footerBottomRow {
    width: 100%;
    margin: 0;
    padding: 0 9px 36px;
  }
}

.legalLinkWrapper {
  flex-flow: wrap;
  display: flex;
}

.recaptchaTermsWrapper {
  margin-top: 18px;
  padding-left: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

@media (width <= 768px) {
  .recaptchaTermsWrapper {
    color: #666;
    margin-top: 9px;
    padding-right: 9px;
  }
}

.recaptchaLink {
  color: #222;
}

.recaptchaLink:hover {
  color: #444;
}

@media (width <= 768px) {
  .recaptchaLink {
    text-align: center;
    color: #666;
  }

  .copyrightLink {
    text-align: center;
    color: #666;
    margin-top: 18px;
  }
}

.monogramIconWrapper {
  justify-content: center;
  margin-top: 36px;
  display: flex;
}

@media (width <= 768px) {
  .monogramIconWrapper {
    margin-top: 18px;
  }
}

.monogramIcon {
  text-align: center;
  width: 100%;
  height: 72px;
}
