:local .row {
  flex-wrap: wrap;
  margin-left: -9px;
  margin-right: -9px;
  display: flex;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .row {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
  }
}

:local .top {
  align-items: flex-start;
}

:local .bottom {
  align-items: flex-end;
}

:local .vCenter {
  align-items: center;
}

:local .left {
  justify-content: flex-start;
}

:local .right {
  justify-content: flex-end;
}

:local .hCenter {
  justify-content: center;
}
