:local .col {
  position: relative;
  min-width: 0;
  min-height: 1px;
  padding-left: 9px;
  padding-right: 9px;
  flex-grow: 1;
}
:local .colMobile1 {
  flex: 0 0 8.3333333333%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}
:local .colMobileOffset1 {
  margin-left: 8.3333333333%;
}
:local .colMobilePull1 {
  right: 8.3333333333%;
}
:local .colMobilePush1 {
  left: 8.3333333333%;
}
:local .colMobile2 {
  flex: 0 0 16.6666666667%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
:local .colMobileOffset2 {
  margin-left: 16.6666666667%;
}
:local .colMobilePull2 {
  right: 16.6666666667%;
}
:local .colMobilePush2 {
  left: 16.6666666667%;
}
:local .colMobile3 {
  flex: 0 0 25%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
:local .colMobileOffset3 {
  margin-left: 25%;
}
:local .colMobilePull3 {
  right: 25%;
}
:local .colMobilePush3 {
  left: 25%;
}
:local .colMobile4 {
  flex: 0 0 33.3333333333%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}
:local .colMobileOffset4 {
  margin-left: 33.3333333333%;
}
:local .colMobilePull4 {
  right: 33.3333333333%;
}
:local .colMobilePush4 {
  left: 33.3333333333%;
}
:local .colMobile5 {
  flex: 0 0 41.6666666667%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
}
:local .colMobileOffset5 {
  margin-left: 41.6666666667%;
}
:local .colMobilePull5 {
  right: 41.6666666667%;
}
:local .colMobilePush5 {
  left: 41.6666666667%;
}
:local .colMobile6 {
  flex: 0 0 50%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
:local .colMobileOffset6 {
  margin-left: 50%;
}
:local .colMobilePull6 {
  right: 50%;
}
:local .colMobilePush6 {
  left: 50%;
}
:local .colMobile7 {
  flex: 0 0 58.3333333333%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
}
:local .colMobileOffset7 {
  margin-left: 58.3333333333%;
}
:local .colMobilePull7 {
  right: 58.3333333333%;
}
:local .colMobilePush7 {
  left: 58.3333333333%;
}
:local .colMobile8 {
  flex: 0 0 66.6666666667%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
}
:local .colMobileOffset8 {
  margin-left: 66.6666666667%;
}
:local .colMobilePull8 {
  right: 66.6666666667%;
}
:local .colMobilePush8 {
  left: 66.6666666667%;
}
:local .colMobile9 {
  flex: 0 0 75%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
:local .colMobileOffset9 {
  margin-left: 75%;
}
:local .colMobilePull9 {
  right: 75%;
}
:local .colMobilePush9 {
  left: 75%;
}
:local .colMobile10 {
  flex: 0 0 83.3333333333%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
}
:local .colMobileOffset10 {
  margin-left: 83.3333333333%;
}
:local .colMobilePull10 {
  right: 83.3333333333%;
}
:local .colMobilePush10 {
  left: 83.3333333333%;
}
:local .colMobile11 {
  flex: 0 0 91.6666666667%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
}
:local .colMobileOffset11 {
  margin-left: 91.6666666667%;
}
:local .colMobilePull11 {
  right: 91.6666666667%;
}
:local .colMobilePush11 {
  left: 91.6666666667%;
}
:local .colMobile12 {
  flex: 0 0 100%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :local .colMobile12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
:local .colMobileOffset12 {
  margin-left: 100%;
}
:local .colMobilePull12 {
  right: 100%;
}
:local .colMobilePush12 {
  left: 100%;
}
:local .colMobileFirst {
  order: -1;
}
:local .colMobileLast {
  order: 1;
}
:local .colMobileTop {
  align-self: flex-start;
}
:local .colMobileCenter {
  align-self: center;
}
:local .colMobileBottom {
  align-self: flex-end;
}
:local .colMobileStretch {
  align-self: stretch;
}
@media (min-width: 569px) {
  :local .colTabletPortrait1 {
    flex: 0 0 8.3333333333%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset1 {
    margin-left: 8.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull1 {
    right: 8.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush1 {
    left: 8.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait2 {
    flex: 0 0 16.6666666667%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset2 {
    margin-left: 16.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull2 {
    right: 16.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush2 {
    left: 16.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait3 {
    flex: 0 0 25%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset3 {
    margin-left: 25%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull3 {
    right: 25%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush3 {
    left: 25%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait4 {
    flex: 0 0 33.3333333333%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset4 {
    margin-left: 33.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull4 {
    right: 33.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush4 {
    left: 33.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait5 {
    flex: 0 0 41.6666666667%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset5 {
    margin-left: 41.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull5 {
    right: 41.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush5 {
    left: 41.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait6 {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset6 {
    margin-left: 50%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull6 {
    right: 50%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush6 {
    left: 50%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait7 {
    flex: 0 0 58.3333333333%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset7 {
    margin-left: 58.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull7 {
    right: 58.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush7 {
    left: 58.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait8 {
    flex: 0 0 66.6666666667%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset8 {
    margin-left: 66.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull8 {
    right: 66.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush8 {
    left: 66.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait9 {
    flex: 0 0 75%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset9 {
    margin-left: 75%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull9 {
    right: 75%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush9 {
    left: 75%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait10 {
    flex: 0 0 83.3333333333%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset10 {
    margin-left: 83.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull10 {
    right: 83.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush10 {
    left: 83.3333333333%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait11 {
    flex: 0 0 91.6666666667%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull11 {
    right: 91.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush11 {
    left: 91.6666666667%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortrait12 {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 569px) and (-ms-high-contrast: active), (min-width: 569px) and (-ms-high-contrast: none) {
  :local .colTabletPortrait12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitOffset12 {
    margin-left: 100%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPull12 {
    right: 100%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitPush12 {
    left: 100%;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitFirst {
    order: -1;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitLast {
    order: 1;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitTop {
    align-self: flex-start;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitCenter {
    align-self: center;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitBottom {
    align-self: flex-end;
  }
}
@media (min-width: 569px) {
  :local .colTabletPortraitStretch {
    align-self: stretch;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape1 {
    flex: 0 0 8.3333333333%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset1 {
    margin-left: 8.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull1 {
    right: 8.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush1 {
    left: 8.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape2 {
    flex: 0 0 16.6666666667%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset2 {
    margin-left: 16.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull2 {
    right: 16.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush2 {
    left: 16.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape3 {
    flex: 0 0 25%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset3 {
    margin-left: 25%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull3 {
    right: 25%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush3 {
    left: 25%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape4 {
    flex: 0 0 33.3333333333%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset4 {
    margin-left: 33.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull4 {
    right: 33.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush4 {
    left: 33.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape5 {
    flex: 0 0 41.6666666667%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset5 {
    margin-left: 41.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull5 {
    right: 41.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush5 {
    left: 41.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape6 {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset6 {
    margin-left: 50%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull6 {
    right: 50%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush6 {
    left: 50%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape7 {
    flex: 0 0 58.3333333333%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset7 {
    margin-left: 58.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull7 {
    right: 58.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush7 {
    left: 58.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape8 {
    flex: 0 0 66.6666666667%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset8 {
    margin-left: 66.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull8 {
    right: 66.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush8 {
    left: 66.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape9 {
    flex: 0 0 75%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset9 {
    margin-left: 75%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull9 {
    right: 75%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush9 {
    left: 75%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape10 {
    flex: 0 0 83.3333333333%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset10 {
    margin-left: 83.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull10 {
    right: 83.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush10 {
    left: 83.3333333333%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape11 {
    flex: 0 0 91.6666666667%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull11 {
    right: 91.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush11 {
    left: 91.6666666667%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscape12 {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 769px) and (-ms-high-contrast: active), (min-width: 769px) and (-ms-high-contrast: none) {
  :local .colTabletLandscape12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeOffset12 {
    margin-left: 100%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePull12 {
    right: 100%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapePush12 {
    left: 100%;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeFirst {
    order: -1;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeLast {
    order: 1;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeTop {
    align-self: flex-start;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeCenter {
    align-self: center;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeBottom {
    align-self: flex-end;
  }
}
@media (min-width: 769px) {
  :local .colTabletLandscapeStretch {
    align-self: stretch;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop1 {
    flex: 0 0 8.3333333333%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset1 {
    margin-left: 8.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull1 {
    right: 8.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush1 {
    left: 8.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop2 {
    flex: 0 0 16.6666666667%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset2 {
    margin-left: 16.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull2 {
    right: 16.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush2 {
    left: 16.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop3 {
    flex: 0 0 25%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset3 {
    margin-left: 25%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull3 {
    right: 25%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush3 {
    left: 25%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop4 {
    flex: 0 0 33.3333333333%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset4 {
    margin-left: 33.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull4 {
    right: 33.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush4 {
    left: 33.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop5 {
    flex: 0 0 41.6666666667%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset5 {
    margin-left: 41.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull5 {
    right: 41.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush5 {
    left: 41.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop6 {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset6 {
    margin-left: 50%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull6 {
    right: 50%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush6 {
    left: 50%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop7 {
    flex: 0 0 58.3333333333%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset7 {
    margin-left: 58.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull7 {
    right: 58.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush7 {
    left: 58.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop8 {
    flex: 0 0 66.6666666667%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset8 {
    margin-left: 66.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull8 {
    right: 66.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush8 {
    left: 66.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop9 {
    flex: 0 0 75%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset9 {
    margin-left: 75%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull9 {
    right: 75%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush9 {
    left: 75%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop10 {
    flex: 0 0 83.3333333333%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset10 {
    margin-left: 83.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull10 {
    right: 83.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush10 {
    left: 83.3333333333%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop11 {
    flex: 0 0 91.6666666667%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull11 {
    right: 91.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush11 {
    left: 91.6666666667%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktop12 {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
  :local .colDesktop12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopOffset12 {
    margin-left: 100%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPull12 {
    right: 100%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopPush12 {
    left: 100%;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopFirst {
    order: -1;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopLast {
    order: 1;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopTop {
    align-self: flex-start;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopCenter {
    align-self: center;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopBottom {
    align-self: flex-end;
  }
}
@media (min-width: 1025px) {
  :local .colDesktopStretch {
    align-self: stretch;
  }
}