.row {
  display: flex;
  flex-direction: row;
  height: 24px;
  align-items: center;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  margin-top: 9px;
}
@media (max-width: 768px) {
  .row {
    height: 30px;
  }
}
.row.isMobile {
  margin-top: 0;
}

.listItem {
  display: flex;
  margin-right: 18px;
}
.listItem:last-child {
  margin-right: 0;
}
@media (max-width: 768px) {
  .listItem {
    margin-right: 18px;
  }
}

.item {
  display: flex;
  margin-right: 9px;
}
.item:last-child {
  margin-right: 0;
}
@media (max-width: 768px) {
  .item {
    margin-right: 18px;
  }
}

.link {
  text-decoration: none;
  color: #bbb;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent;
  transition: all 0.15s linear;
}
.link:hover {
  color: #bbb;
  text-decoration: underline;
}

.facebook {
  color: #222;
}

.facebook:hover {
  color: #444;
  background: #fff;
}

.pinterest {
  color: #222;
}

.pinterest:hover {
  color: #444;
  background: #fff;
}

.instagram {
  color: #222;
}

.instagram:hover {
  color: #444;
  background: #fff;
}