.link {
  height: 24px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .link {
    height: 40px;
  }
}
.link .icon {
  height: 24px;
  max-height: none;
}
@media (max-width: 768px) {
  .link .icon {
    height: 40px;
  }
}

.placeholder {
  min-height: 24px;
}
@media (max-width: 768px) {
  .placeholder {
    min-height: 40px;
  }
}