.row {
  text-transform: uppercase;
  letter-spacing: 1px;
  flex-direction: row;
  align-items: center;
  height: 24px;
  margin-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  display: flex;
}

@media (width <= 768px) {
  .row {
    height: 30px;
  }
}

.row.isMobile {
  margin-top: 0;
}

.listItem {
  margin-right: 18px;
  display: flex;
}

.listItem:last-child {
  margin-right: 0;
}

@media (width <= 768px) {
  .listItem {
    margin-right: 18px;
  }
}

.item {
  margin-right: 9px;
  display: flex;
}

.item:last-child {
  margin-right: 0;
}

@media (width <= 768px) {
  .item {
    margin-right: 18px;
  }
}

.link {
  color: #bbb;
  background: none;
  width: 20px;
  height: 20px;
  text-decoration: none;
  transition: all .15s linear;
  display: inline-block;
}

.link:hover {
  color: #bbb;
  text-decoration: underline;
}

.facebook {
  color: #222;
}

.facebook:hover {
  color: #444;
  background: #fff;
}

.pinterest {
  color: #222;
}

.pinterest:hover {
  color: #444;
  background: #fff;
}

.instagram {
  color: #222;
}

.instagram:hover {
  color: #444;
  background: #fff;
}
