.container {
  font-size: 12px;
  font-weight: 300;
  display: flex;
  flex-flow: row wrap;
  padding: 0 9px;
}
@media (max-width: 768px) {
  .container {
    margin-bottom: 27px;
  }
}

.label {
  padding-right: 4.5px;
}

.linksContainer {
  display: flex;
  flex-flow: row wrap;
}

.spacer {
  padding: 0 4.5px;
  margin: auto 0;
}
.spacer::after {
  content: "|";
}
.spacer:last-child:after {
  content: "";
}