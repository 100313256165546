.container.isMobile {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.subheader {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
