/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.footer {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  padding: 45px 0 27px;
  background: #fff;
  color: #000;
}
@media (min-width: 1025px) {
  .footer {
    padding: 54px 0 72px;
    border-top: 1px solid #bbb;
  }
}
@media print {
  .footer {
    display: none;
  }
}
@media (max-width: 768px) {
  .footer {
    padding: 0;
  }
}

.footerRow {
  width: calc(100% - (2 * 36px));
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 72px;
  border-bottom: 1px solid #bbb;
}
@media (max-width: 768px) {
  .footerRow {
    padding-bottom: 27px;
    border-color: #bbb;
  }
}

.additionalLinkContainer {
  padding-top: 27px;
}