.footer {
  color: #000;
  background: #fff;
  padding: 45px 0 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

@media (width >= 1025px) {
  .footer {
    border-top: 1px solid #bbb;
    padding: 54px 0 72px;
  }
}

@media print {
  .footer {
    display: none;
  }
}

@media (width <= 768px) {
  .footer {
    padding: 0;
  }
}

.footerRow {
  width: calc(100% - 72px);
  width: calc(100% - 2 * var(--sassy-layout-offset));
  border-bottom: 1px solid #bbb;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 72px;
}

@media (width <= 768px) {
  .footerRow {
    border-color: #bbb;
    padding-bottom: 27px;
  }
}

.additionalLinkContainer {
  padding-top: 27px;
}
