.column {
  flex-direction: column;
  display: flex;
}

@media (width <= 768px) {
  .column {
    margin: 18px 0 0 18px;
  }
}

.title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  margin: 0 0 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}
