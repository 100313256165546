.container {
  flex-flow: wrap;
  padding: 0 9px;
  font-size: 12px;
  font-weight: 300;
  display: flex;
}

@media (width <= 768px) {
  .container {
    margin-bottom: 27px;
  }
}

.label {
  padding-right: 4.5px;
}

.linksContainer {
  flex-flow: wrap;
  display: flex;
}

.spacer {
  margin: auto 0;
  padding: 0 4.5px;
}

.spacer:after {
  content: "|";
}

.spacer:last-child:after {
  content: "";
}
