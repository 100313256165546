.standardLink {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: underline;
}
.standardLink:hover {
  color: #444;
}
@media (hover: none) {
  .standardLink:hover {
    color: #000;
  }
}

.standardLinkNoUnderline {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
}
.standardLinkNoUnderline:hover {
  color: #444;
}
@media (hover: none) {
  .standardLinkNoUnderline:hover {
    color: #000;
  }
}

.invertedLink {
  color: #444;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
}
.invertedLink:hover {
  color: #444;
  text-decoration: underline;
}

.contrastLinkLightInverse {
  text-decoration: none;
  color: #fff;
}
.contrastLinkLightInverse:hover {
  color: #fff;
  text-decoration: underline;
}

.contrastLinkLight {
  text-decoration: none;
  color: #fff;
}
.contrastLinkLight a:hover, .contrastLinkLight:hover {
  color: #fff;
  text-decoration: underline;
}

.contrastLinkAlternate {
  text-decoration: none;
  color: #bbb;
}
.contrastLinkAlternate:hover {
  color: #bbb;
  text-decoration: underline;
}

.contrastLinkNoEffect {
  text-decoration: none;
  color: #fff;
}
.contrastLinkNoEffect:hover {
  color: #fff;
}

.standardLinkNoEffect {
  text-decoration: none;
  color: #000;
}
.standardLinkNoEffect:hover {
  color: #000;
}

.linkNoDecoration {
  text-decoration: none;
}