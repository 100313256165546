.container.isMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.subheader {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}