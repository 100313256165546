.item {
  padding: 0;
  line-height: 27px;
}

@media (width <= 768px) {
  .item, .item.isConnectLink {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 14px;
    font-weight: 300;
  }
}

.item.isLegalLink {
  margin: 0;
  padding: 0 9px;
}

@media (width <= 768px) {
  .item.isLegalLink {
    width: auto;
    margin-bottom: 9px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 12px;
    font-weight: 300;
    text-decoration: underline;
  }

  .item.isLegalLink:first-child {
    width: auto;
  }
}

.item.isGlobalSitesLink {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: revert;
}

.contact1stdibsLink {
  color: #000;
  cursor: pointer;
  text-decoration: none;
  transition: color .2s;
}

.contact1stdibsLink:hover {
  color: #444;
}

@media (hover: none) {
  .contact1stdibsLink:hover {
    color: #000;
  }
}
