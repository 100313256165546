.item {
  padding: 0;
  line-height: 27px;
}
@media (max-width: 768px) {
  .item {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .item.isConnectLink {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 300;
  }
}
.item.isLegalLink {
  margin: 0;
  padding: 0 9px;
}
@media (max-width: 768px) {
  .item.isLegalLink {
    margin-bottom: 9px;
    text-decoration: underline;
    width: auto;
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 12px;
    font-weight: 300;
  }
  .item.isLegalLink:first-child {
    width: auto;
  }
}
.item.isGlobalSitesLink {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: revert;
}

.contact1stdibsLink {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
}
.contact1stdibsLink:hover {
  color: #444;
}
@media (hover: none) {
  .contact1stdibsLink:hover {
    color: #000;
  }
}