.column {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .column {
    margin: 18px 0 0 18px;
  }
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 18px;
  color: #000;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}