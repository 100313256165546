.link {
  align-items: center;
  height: 24px;
  display: flex;
  overflow: hidden;
}

@media (width <= 768px) {
  .link {
    height: 40px;
  }
}

.link .icon {
  height: 24px;
  max-height: none;
}

@media (width <= 768px) {
  .link .icon {
    height: 40px;
  }
}

.placeholder {
  min-height: 24px;
}

@media (width <= 768px) {
  .placeholder {
    min-height: 40px;
  }
}
